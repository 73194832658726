.loginPage {
    background: #ffcb05;
    @media (max-width: 600px) {
        height: 100vh;
    }
    .leftBlock {
        max-width: 60%;
        background: #ffcb05;
        box-shadow: none;
        img {
            object-fit: scale-down;
            width: auto !important;
            object-position: bottom;
            height: 100vh;
        }
    }
    .rightBlock {
        width: 100%;
        max-width: 700px;
        padding: 0 1rem;
        @media (max-width: 900px) {
            width: 90%;
            margin: 0 auto;
            height: 100%;
        }
        @media (max-width: 600px) {
            width: 100% !important;
            padding: 1rem 1.5rem !important;
            .container {
                width: 100%;
                min-height: inherit;
                margin: 60px auto;
            }
        }
    }
    .loginButton {
        background: #00a651;
        margin-top: 1rem;
        font-size: 1.125rem;
        &:hover {
            background: #00a651;
        }
    }
    .container {
        margin: 0 auto;
        width: 50%;
        @media (min-width: 2000px) {
            width: 70% !important;
        }
    }
    .mobileHeaderLogo {
        display: none;
        img {
            width: 100%;
        }
        @media (max-width: 899px) {
            display: block;
        }
    }
}
.logo {
    width: 200px;
    margin: 0 auto 4rem;
}

.loginpageform {
    input {
        padding-left: 30px;
    }
}
.viewIcon {
    background-color: #e8f0fe;
}
