.newVedioButton,
.addVideoButton,
.backButton,
.updateButton,
.submitBtn {
    background-color: #00a651;
    color: #ffffff;
    &:hover {
        background: #00a651;
    }
}

td {
    font-size: clamp(0.75rem, 0.72vw, 1.75rem) !important;
}
.backButton {
    margin-bottom: 1rem;
    @media (max-width: 999px) {
        display: none;
    }
}
.buttonGroup {
    display: flex;
    align-items: center;
    gap: 1em;
    .filterForm {
        max-height: 40px;
        padding: 0 !important;
    }
}
//mobile
.videoDescription {
    font-size: clamp(0.875rem, 0.833vw, 2rem);
    font-weight: 400;
    @media (max-width: 600px) {
        color: #000;
        font-family: Roboto;
        font-style: normal;
        letter-spacing: -0.5px;
    }
}
.tag {
    @media (max-width: 600px) {
        display: none;
    }
}
.tagMobile {
    span {
        padding: 4px 10px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }
    @media (min-width: 600px) {
        display: none;
    }
}
.mobileBack {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
    padding-block: 1rem;
    @media (min-width: 999px) {
        display: none;
    }
    button {
        padding: 0 !important;
        min-width: inherit !important;
    }
}
h4 {
    @media (max-width: 600px) {
        font-size: clamp(1rem, 4.18vw, 2.125rem) !important;
    }
    color: #000 !important;
    font-style: normal !important;
}
// Data Table Container
.dataTable {
    max-width: 100% !important ;
    padding: 0;
}

// Video cards
.videoCard {
    border-radius: 0;
    border: 5px solid #ffcb05;
    .pauseIcon {
        position: absolute;
        z-index: 2;
        bottom: 10%;
        right: 7.5%;
        background-color: #0000007d;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }
    .hoverCardContent {
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }
    &:hover {
        .hoverCardContent {
            opacity: 1;
        }
        .cardImage {
            &::after {
                top: 0;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba(22, 28, 36, 0.72);
            }
        }
        .pauseIcon {
            opacity: 0;
            color: #000000;
            background-color: #ffff00fd;
        }
    }
    @media (max-width: 600px) {
        display: none;
    }
}
.mobileVideoCard {
    display: none;
    border-radius: 8px;
    background: #ffcb05;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
    min-height: 100px;
    max-height: 100px;
    padding: 8px;
    @media (max-width: 600px) {
        display: flex;

        gap: 2%;
    }
    .mobileCardImage {
        width: 30% !important;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .cardContentMobile {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a {
            font-size: clamp(0.875rem, 3.72vw, 2rem) !important;
            max-height: 60px !important;
            overflow: hidden;
            @media (max-width: 600px) {
                color: #000000;
            }
        }
        p {
            @media (max-width: 600px) {
                color: #050505;
                font-family: Public Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
            }
        }
    }
    .actionBtn {
        width: 8%;
        display: flex;
        align-items: center;
    }
}

//import Csv File Styles
.importCsvContainer {
    max-width: 1000;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .importCsvFile {
        display: flex;
        align-items: center;
        padding: 5em 1.5em 5em 6em;
        background-color: #ffffff;
        border-radius: 10px;
        border: 2px dashed #ffcc00;
    }
}
.csvDataHeader {
    margin-bottom: 0.3125rem;
    font-size: 17px;
}
.csvImportButtonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
    .downloadButton,
    .doneButton {
        background: #00a651;
        color: white;
    }
}
.tablePagination {
    background-color: #ffcb05;
}

//addVideoFom
.addVideoFormContainer {
    margin-top: 0.5rem;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    .leftBlock {
        width: 40%;
        p {
            text-align: center;
            font-size: clamp(0.75rem, 1vw, 1.25rem);
        }
        .imageBlock {
            display: flex;
            justify-content: center;
        }
    }
    .rightBlock {
        width: 50%;
    }
}

.tableCell {
    padding: 8px !important;
}
.watchVideoContainer {
    @media (min-width: 1900px) {
        max-width: 1700px !important;
    }
    .playVideoCard {
        border: 7px solid #ffffff;
        // background: transparent;
        border-bottom: none;
        border-radius: 0;
        iframe {
            @media (min-width: 1900px) {
                height: 1000px !important;
            }
        }
        @media (max-width: 600px) {
            display: none;
        }
    }
    .playVideoCardOne {
        border: 7px solid #ffffff;
        border-radius: 0;
        min-height: 550px;
        max-height: 550px;
        iframe {
            min-height: 550px;
            max-height: 550px;
            @media (min-width: 1900px) {
                height: 1000px !important;
            }
        }
        @media (max-width: 600px) {
            min-height: 220px;
            max-height: 220px;
            border: 0;
            iframe {
                min-height: 220px;
                max-height: 220px;
                border-radius: 8px !important;
                border: 0 !important;
            }
        }
    }
    .playVideoCardMobile {
        // border: 1px solid #ffffff;
        border-bottom: none;
        border-radius: 0;
        background: transparent;
        @media (min-width: 600px) {
            display: none;
        }
        iframe {
            border-radius: 8px !important;
            border: 0 !important;
        }
    }
}
