@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700&display=swap");

.videoGrid {
    max-width: 100% !important;
    .searchForm {
        padding: 0 !important;
        font-size: 0.625rem;
    }
}
.watchVideoContainer {
    @media (min-width: 1900px) {
        max-width: 1700px !important;
    }
    .playVideoCard {
        border: 7px solid #ffffff;
        // background: transparent;
        border-bottom: none;
        border-radius: 0;
        iframe {
            @media (min-width: 1900px) {
                height: 1000px !important;
            }
        }
        @media (max-width: 600px) {
            display: none;
        }
    }
}

.playVideoCardMobile {
    // border: 1px solid #ffffff;
    border-bottom: none;
    border-radius: 0;
    background: transparent;
    @media (min-width: 600px) {
        display: none;
    }
    iframe {
        border-radius: 8px !important;
        border: 0 !important;
    }
}
.showTags {
    background-color: #ffcb03;
    margin-top: 2px;
    margin-bottom: 2px;
    color: #ff4444;
    &:hover {
        background-color: #ffcb03;
    }
}
.slectedTag {
    background-color: #ff4444;
    color: #ffcb03;
    margin-left: 2px;
    font-weight: 700;

    &:hover {
        background-color: #ff4444;
    }
}
.showTagsContainer {
    border: 3px dotted rgb(255, 203, 3);
    padding: 10px;
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 4px;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px #0000004d;
        // border-radius: 10px;
    }
}
.webHeader {
    @media (max-width: 1024px) {
        display: none;
    }
}
.mobileHeader {
    display: none;
    margin: 0.5rem 0 0;
    padding: 0;
    p {
        font-size: clamp(1.25rem, 5.58vw, 3rem);
        color: #000;
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
    }
    @media (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    button {
        padding: 0 !important;
        min-width: inherit !important;
    }
}
header {
    background-color: transparent !important;
    box-shadow: none !important;
}
.mobileSearch {
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
}

//menu list styles
.menuListGridContainer {
    padding: 3rem 0;
    color: #292929;
    li {
        font-size: clamp(1.125rem, 5.58vw, 1.625rem);
    }
    h6 {
        font-size: clamp(1.875rem, 7.44vw, 2.125rem);
        font-weight: 500;
        font-style: normal;
    }
}

.pagination {
    margin: 1rem 0;
    ul {
        justify-content: center;

        button {
            @media (max-width: 476px) {
                min-width: 25px !important;
                height: 25px !important;
            }
        }
    }
}
