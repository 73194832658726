.profileIcon {
    color: #000000;
   
}
.profileBox {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #212b36;
    font-weight: 400;
    font-size: clamp(14px, 0.9vw, 28px);
    &:hover {
        color: #ffffff;
        .profileIcon {
            color: #ffffff;
        }
    }
}
.menuItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    color: #212b36;
    font-weight: 400;
    // font-size: clamp(14px, 0.9vw, 28px);  
    padding: .75rem 1rem;
}
