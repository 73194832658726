.dashBoard {
    padding: 1rem;
    background-color: #fdedb4;

    .dashboardPage {
        background-color: #ffffff;
        border-radius: 0.65rem;
        margin-bottom: 1rem;
        padding: 1px;
        width: 100%;

        @media (max-width: 900px) {
            padding: 1px 10px;
        }
        h3 {
            font-size: clamp(1.2rem, 1.25vw, 40px);
            font-weight: 400;
            color: #ff4444;
            margin: 1.5rem 2rem 1rem;
        }

        .dateBlock {
            margin: 1.5rem 2em 1.5em auto;
            float: right;
            display: flex;
            align-items: center;
            @media (max-width: 600px) {
                flex-direction: column;
                align-items: flex-start;
                margin: 0;
                .datePicker {
                    margin-top: 1rem;
                    span {
                        margin-inline: 0px;
                    }
                }
            }
            input {
                font-family: inherit;
                width: 25%;
                padding: 0.5rem 0.625rem;
                border: none;
                border-radius: 0.25rem;
                border: 2px solid #dbdce3;
                min-width: 8rem;
                max-width: 10rem;
            }
            span {
                margin-inline: 10px;
            }
            label {
                font-size: clamp(14px, 0.915vw, 1.5rem);
                letter-spacing: 0.5px;
            }
        }
        .graphWrapper {
            margin-right: 2rem;
            margin: 2rem 2rem 1rem 0;
        }
    }
}
