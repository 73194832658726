#addUser {
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    input {
        padding: 0.75rem 0.875rem;
    }
    .submitBtn {
        &:hover {
            background: #00a651;
        }
    }

    .containerBlock {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        @media (min-width: 2000px) {
            row-gap: 2rem;
        }
    }
    .container {
        width: 70%;
        max-width: 999\px;
        margin: 0 auto;
        padding: 2.5rem;
        @media (max-width: 768px) {
            padding: 2rem 1rem;
            width: 100%;
        }
    }
    .addUserCard {
        background: none;
        border: none;
        box-shadow: none;
        padding-block: 1rem;
    }
}
