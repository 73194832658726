.viewProfile {
    margin-top: 20px;
    height: calc(100% - 60px);
    h4 {
        line-height: 1;
        margin-bottom: 0;
        font-size: clamp(1.2rem, 1.5vw, 2.5rem);
    }
    .label {
        text-transform: uppercase;
        font-size: clamp(0.875rem, 0.9vw, 1.75rem);
        line-height: 1;
        opacity: 0.75;
        margin: 5px 0;
    }
    .value {
        font-size: clamp(1rem, 1vw, 2rem);
        line-height: 1;
        margin: 0.75rem 0 1.75rem;
    }
}
.userProfileView {
    max-width: 800px;
    margin: 1rem auto 0;
    padding: 2rem 2rem 0.5rem;
}
