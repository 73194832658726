.navLinksColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    button {
        padding: 0.625rem 0 !important;
    }
    @media (min-width: 1024px) {
        display: none;
    }
}
.navLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 0;
    button {
        padding: 0;
    }
    @media (min-width: 1024px) {
        .menuIcon {
            display: none;
        }
    }
}
.profileViewPage {
    max-width: clamp(290px, 80%, 800px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        margin: 0rem auto;
        height: calc(100vh - 2rem);
    }
    .header {
        @media (min-width: 1024px) {
            margin-right: 30px;
        }

        .profilePic {
            width: clamp(120px, 6vw, 240px);
            height: clamp(120px, 6vw, 240px);
        }
        .userTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem 1.5rem;
            h6 {
                font-size: clamp(1.125rem, 1.5vw, 2.5rem);
                font-weight: 400;
                font-style: normal;
                color: #ffffff;
                flex-grow: 1;
                padding-left: 1rem;
            }
            @media (min-width: 1024px) {
                padding: 1rem;
            }
        }
    }

    .userDetailContainer {
        .legend {
            font-weight: 500;
            margin-bottom: 1em;
        }
        .buttonGroup {
            display: flex;
            align-items: center;
            gap: 2%;
            margin-top: 1.5rem;
        }
        .editBtn {
            background-color: #ff5555;
            gap: 8px;
            width: 40%;
            padding-inline: 0;
            font-size: clamp(14px, 0.72vw, 28px);
        }
        .updatePassword {
            gap: 8px;
            width: 58%;
            padding-inline: 0;
            font-size: clamp(0.875rem, 0.72vw, 1.75rem);
            &:hover {
                background: #00a651;
            }
        }
        .gridItem {
            h6 {
                font-size: clamp(0.875rem, 1vw, 2rem);
                font-weight: 500;
                color: #000;
            }
            p {
                font-size: clamp(0.875rem, 1vw, 2rem);
                font-weight: 500;
                margin: 0;
            }
            .name {
                color: #717171;
                font-size: clamp(0.75rem, 0.9vw, 2rem);
                font-weight: 500;
                line-height: 100%; /* 14px */
                text-transform: capitalize;
                margin-bottom: 0.5rem;
            }
            .value {
                border-radius: 4px;
                color: #292929;
                border: 1px solid rgba(0, 0, 0, 0.5);
                background: rgba(255, 255, 255, 0.1);
                padding: 0.5em 0.65em;
                min-height: 2.3125rem;
            }
        }
    }
}

#profileEditPage {
    max-width: clamp(290px, 80%, 1100px);
    margin: 1rem auto;

    .userDpEdit {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 1.5rem;

        .avatar {
            border: 2px solid #ffcb05;
            position: relative;
            background-color: #ffffff;
            svg {
                fill: #ffcb05;
            }
        }
        .editCamera {
            margin-left: -3rem;
            z-index: 1;
        }
    }
    .fieldsContainer {
        padding-top: 1rem;
        @media (min-width: 768px) {
            max-width: 700px;
            margin: 0 auto;
        }
        p {
            margin: 0;
            color: #717171;
            font-size: clamp(0.75rem, 0.9vw, 2rem);
            font-weight: 500;
            line-height: 100%; /* 14px */
            text-transform: capitalize;
            margin-bottom: 0.5rem;
            // height: calc(100% - 40px);
        }
        input {
            font-size: clamp(0.875rem, 1vw, 2rem);
            font-weight: 500;
            color: #000000cc;
            border: 1px solid #00000080;
            background: #ffffffe6;
            padding: 0.5em 0.65em;
            border-radius: 5px;
            &:focus {
                box-shadow: 0 1px 0 0 #00000080;
            }
        }
    }

    .btnGroup {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 3rem;
        padding: 0;
        @media (min-width: 768px) {
            max-width: 700px;
            margin: 0 auto;
            margin-top: 2rem;
        }
        button {
            border-radius: 4px;
            padding: 0.65rem 2rem;
        }
        .updateBtn {
            background: #00a651;
            & :hover {
                background: #00a651 !important;
            }
        }

        @media (max-width: 576px) {
            .updateBtn,
            .cancelBtn {
                width: 100%;
            }
        }
    }
}
.cancelBtn {
    background-color: #fff;
    color: #292929;
    & :hover {
        background-color: #fff;
    }
}
.updateBtn {
    background: #00a651;
    & :hover {
        background: #00a651 !important;
    }
}
.updatePsdPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 10rem);
    padding: 2rem 0;
    .fieldsContainer {
        width: 400px;
        padding: 0 1rem;
    }
    .UpdateTxt {
        text-align: center;
        margin-bottom: 1rem;
        p {
            font-size: clamp(1rem, 1.5vw, 2rem);
            font-weight: 600;
        }
        @media (max-width: 476px) {
            margin-bottom: 1.5rem;
        }
    }
    .updatePsdBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        margin-top: 2rem;
        button {
            padding: 0.625rem 2rem;
            font-size: clamp(0.875rem, 0.833vw, 1.3rem);
            width: 100%;
        }
    }
}
.webBackBtn {
    background-color: #00a651;
    color: #ffffff;
    @media (max-width: 999px) {
        display: none;
    }
}
