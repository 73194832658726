.newDocumentButton,
.addDocumentButton,
.updateButton,.folderButton {
    background: #00A651;
    &:hover {
        background: #00A651;
    }
}
.buttonGroup {
    display: flex;
    gap: 1em;
}
.navigationLinks {
    display: flex;
    gap: 1em;
    cursor: pointer;
    a {
        color:#ED1C24;
        text-decoration-color: #ED1C24;
    }
}
.img {
    max-width: 50% !important;
}
.dataTable {
    max-width: 100%;
    padding: 0;
}
.buttonGroup {
    display: flex;
    align-items: flex-start;
    gap: 1em;
}
.folderView {
    align-items: center;
    .newDocumentButton {
        max-height: 2.5rem;
    }
}
.folderName, .tagName {
    background-color: #ED1C24;
    color:#ffffff;
    margin-left: 5px;
    margin-top: 5px;
    font-size: .65rem;
    &:hover {
        background-color: #ED1C24;
    }
}
.tablePagination {
    background-color: #ffcb05;
}



.addDocumentFormContainer {
    margin-top: 0.5rem;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    .leftBlock {
        width: 40%;
        p {
            text-align: center;
            font-size: clamp(0.75rem, 1vw, 1.25rem);
        }
        .imageBlock {
            display: flex;
            justify-content: center;
        }
    }
    .rightBlock {
        width: 50%;
    }
}